import {
	SvgIconProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	TypographyProps,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';

import TAGSvgIcon, { IconName } from '@/components/TAGSvgIcon';
import { ContentfulButtonVariant } from '@/types';
import { resolveContentfulButtonVariant } from '@/utils';

import TAGLink from '../TAGLink';
import ImageWrapper from '../ImageWrapper';
import TAGButton from '../TAGButton/TAGButton';

import { tableFromJsonCellStyles, tableFromJsonCtrStyles, tableFromJsonStyles } from './Table.styles';

export interface ITableCellContentStyleProps {
	bgColor: string;
	mobileBgColor: string;
	textVariant: string;
	mobileTextVariant: string;
	eyebrowColor: string;
	alignment: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
	verticalAlignment: string;
	mobileVerticalAlignment: string;
	padding: string;
	mobilePadding: string;
	border: string;
	mobileBorder: string;
}

interface IDisplaySetting {
	displayScreens: Array<'desktop' | 'mobile'>;
}
interface ITableCellText {
	value: string;
	eyebrow: string;
}
interface ITableCellImage {
	url: string;
}
interface ITableCellIcon {
	name: IconName;
	color: string;
}

interface ITableCellButton {
	value: string;
	variant: string;
	mobileVariant: string;
	link: string;
}
interface ITableCellLink {
	url: string;
	openInNewTab: boolean;
	value: string;
}

interface ITableCell extends IDisplaySetting {
	image: ITableCellImage;
	text: ITableCellText;
	icon: ITableCellIcon;
	link: ITableCellLink;
	button: ITableCellButton;
	styles: ITableCellContentStyleProps;
}

interface ITableRow extends IDisplaySetting {
	type: string;
	cells: Array<ITableCell>;
}

export interface ITableFromJson {
	firstColumnStickyOnMobile: boolean;
	rows: Array<ITableRow>;
}
export interface ITableFromJsonProps {
	jsonData?: ITableFromJson;
}

const TableCellContent = (props: { cells: Array<ITableCell>; isSmallScreen: boolean; rowIndex: number }) => {
	const { cells, isSmallScreen, rowIndex } = props;
	return (
		<>
			{cells.map((cell: ITableCell, index: number) =>
				shouldDisplayTableRowOrCell(isSmallScreen, cell.displayScreens) ? (
					<TableCell
						align={cell.styles.alignment || 'center'}
						key={`table_cell_${rowIndex}_${index}`}
						data-test-id={`table_from_json_table_cell_${rowIndex}_${index}`}
						sx={tableFromJsonCellStyles(cell.styles)}
						padding="none"
					>
						{cell.icon?.name && (
							<TAGSvgIcon
								icon={cell.icon?.name}
								htmlColor={cell.icon?.color as SvgIconProps['htmlColor']}
								data-test-id={`table_from_json_table_cell_icon_${rowIndex}_${index}`}
							/>
						)}
						{cell.text?.eyebrow && (
							<Typography
								variant={isSmallScreen ? 'bodyMediumBold' : 'bodyLargeBold'}
								color="success.dark"
								data-test-id={`table_from_json_table_cell_text_eyebrow_${rowIndex}_${index}`}
							>
								{cell.text.eyebrow}
							</Typography>
						)}
						{cell.text?.value && (
							<Typography
								variant={
									isSmallScreen
										? (cell.styles.mobileTextVariant as TypographyProps['variant'])
										: (cell.styles.textVariant as TypographyProps['variant'])
								}
								data-test-id={`table_from_json_table_cell_text_value_${rowIndex}_${index}`}
							>
								<ReactMarkdown>{cell.text.value}</ReactMarkdown>
							</Typography>
						)}
						{cell.image?.url && (
							<ImageWrapper
								src={cell.image.url}
								width={'100%'}
								height={'100%'}
								data-test-id={`table_from_json_table_cell_image_${rowIndex}_${index}`}
							/>
						)}
						{cell.link?.url && (
							<TAGLink
								href={cell.link.url}
								openInNewTab={cell.link.openInNewTab}
								data-test-id={`table_from_json_table_cell_link_${rowIndex}_${index}`}
							>
								<ReactMarkdown>{cell.link.value}</ReactMarkdown>
							</TAGLink>
						)}
						{cell.button?.link && (
							<TAGButton
								size={isSmallScreen ? 'S' : 'M'}
								variant={resolveContentfulButtonVariant(
									isSmallScreen
										? (cell.button.mobileVariant as ContentfulButtonVariant)
										: (cell.button.variant as ContentfulButtonVariant),
									'secondaryDefault'
								)}
								data-test-id={`table_from_json_table_cell_button_${rowIndex}_${index}`}
							>
								<ReactMarkdown>{cell.button.value}</ReactMarkdown>
							</TAGButton>
						)}
					</TableCell>
				) : null
			)}
		</>
	);
};

const TableRowContent = (props: { row: ITableRow; isSmallScreen: boolean; index: number }) => {
	const { row, isSmallScreen, index } = props;
	return (
		<TableRow
			key={`table_from_json_${row.cells?.length}_${index}`}
			data-test-id={`table_from_json_table_row_${index}`}
		>
			<TableCellContent cells={row?.cells} isSmallScreen={isSmallScreen} rowIndex={index} />
		</TableRow>
	);
};

const shouldDisplayTableRowOrCell = (isSmallScreen: boolean, displayScreens: Array<string>) => {
	return (
		(isSmallScreen && displayScreens?.includes('mobile')) || (!isSmallScreen && displayScreens?.includes('desktop'))
	);
};

export default function TableFromJson({ jsonData }: Readonly<ITableFromJsonProps>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (!jsonData) return null;

	const { rows, firstColumnStickyOnMobile } = jsonData;

	return (
		<TableContainer
			sx={tableFromJsonCtrStyles(isSmallScreen, firstColumnStickyOnMobile)}
			data-test-id="table_from_json_container"
		>
			<Table sx={tableFromJsonStyles} data-test-id="table_from_json_table">
				<TableBody data-test-id="table_from_json_table_body">
					{rows.map((row: ITableRow, index: number) =>
						shouldDisplayTableRowOrCell(isSmallScreen, row.displayScreens) ? (
							<TableRowContent
								row={row}
								isSmallScreen={isSmallScreen}
								index={index}
								key={`table_from_json_${row.type}_${index}`}
							/>
						) : null
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
