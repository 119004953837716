import { Fragment } from 'react';

import { SxProps, useMediaQuery, useTheme, Theme } from '@mui/material';

import FocalPointImage, { BrandFolderImage, FocalPointData } from '@/components/FocalPointImage';
import { ImagePriorityContext, optimizeImageUrl, useIsHighPriorityImage } from '@/utils/images/imageOptimization';
import { BACKGROUND_IMAGE_QUALITY } from '@/constants';

import ImageWrapper, { PreloadImage } from '../ImageWrapper/ImageWrapper';
import { resolveImagePosition } from '../FocalPointImage/FocalPointImage.helpers';

import { getBackgroundImageStylesResponsive } from './imageAsset.styles';

export interface IImage {
	image: BrandFolderImage;
	focalPoint: FocalPointData;
}

export type ImgProps = JSX.IntrinsicElements['img'];

const getImageUrl = (img?: IImage) => img?.image?.cdn_url ?? img?.image?.url;

export interface IImageAsset extends ImgProps {
	/**
	 * Required field for the Desktop Image
	 *
	 * @type {IImage}
	 * @memberof IImageAsset
	 */
	desktopImage: IImage;
	/**
	 * Optional field for the Mobile Image
	 *
	 * @type {IImage}
	 * @memberof IImageAsset
	 */
	mobileImage?: IImage;
	/**
	 * Whether to display the image as a background image.
	 *
	 * @type {boolean}
	 * @memberof IImageAsset
	 * @todo rename this to isBackgroundImage
	 */
	isBackgroundImage?: boolean;
	/**
	 * Optional styles to apply to the image.
	 *
	 * @type {SxProps}
	 * @memberof IImageAsset
	 */
	imageSx?: SxProps<Theme>;
	/**
	 * Optional data-test-id attribute for testing purposes
	 *
	 * @type {string}
	 * @memberof IImageAsset
	 */
	dataTestId?: string;
}

export default function ImageAsset({
	desktopImage,
	mobileImage,
	isBackgroundImage,
	imageSx,
	dataTestId,
	...rest
}: Readonly<IImageAsset>) {
	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const imageToDisplay = isMobileScreen && mobileImage?.image ? mobileImage : desktopImage;
	if (isBackgroundImage) {
		return (
			<BackgroundImageAsset
				desktopImage={desktopImage}
				mobileImage={mobileImage}
				imageSx={imageSx}
				dataTestId={dataTestId ? dataTestId : `img_background_focal_point_${imageToDisplay.image?.sys?.id}`}
				{...rest}
			/>
		);
	}

	const imageStyles = imageSx ? [imageSx].flatMap((v) => v) : [];
	const mobileImagePosition = resolveImagePosition(mobileImage?.focalPoint, mobileImage?.image) || '';
	const desktopImagePosition = resolveImagePosition(desktopImage?.focalPoint, desktopImage.image) || '';
	const styles = [
		...imageStyles,
		{
			backgroundPosition: mobileImagePosition ? mobileImagePosition : desktopImagePosition,
			...(mobileImagePosition
				? {
						[theme.breakpoints.up('md')]: {
							backgroundPosition: desktopImagePosition,
						},
				  }
				: {}),
		},
	] as SxProps;
	const defaultSrc = optimizeImageUrl(getImageUrl(imageToDisplay));
	return (
		<picture>
			<ResponsiveImageSource
				desktopImage={desktopImage}
				mobileImage={mobileImage}
				defaultSrc={defaultSrc}
				includeSourceElement={true}
			/>
			<ImagePriorityContext.Provider value={Infinity}>
				<FocalPointImage
					brandFolderImage={imageToDisplay?.image}
					focalPoint={imageToDisplay?.focalPoint}
					imageSx={styles}
					dataTestId={dataTestId}
					{...rest}
				/>
			</ImagePriorityContext.Provider>
		</picture>
	);
}

export function ResponsiveImageSource({
	desktopImage,
	mobileImage,
	defaultSrc,
	includeSourceElement,
}: Readonly<{
	desktopImage?: IImage;
	mobileImage?: IImage;
	defaultSrc?: string;
	includeSourceElement?: boolean;
}>) {
	const highPriority = useIsHighPriorityImage();
	const theme = useTheme();
	const desktopUrl = getImageUrl(desktopImage) ?? defaultSrc;
	const mobileUrl = getImageUrl(mobileImage) ?? desktopUrl;
	const responsiveContent = [
		{
			srcSet: optimizeImageUrl(mobileUrl, theme.breakpoints.values.sm, BACKGROUND_IMAGE_QUALITY),
			media: theme.breakpoints.between('xs', 'sm').replace('@media', ''),
		},
		{
			srcSet: optimizeImageUrl(mobileUrl, theme.breakpoints.values.md, BACKGROUND_IMAGE_QUALITY),
			media: theme.breakpoints.between('sm', 'md').replace('@media', ''),
		},
		{
			srcSet: optimizeImageUrl(desktopUrl, undefined, BACKGROUND_IMAGE_QUALITY),
			media: theme.breakpoints.up('md').replace('@media', ''),
		},
	];

	return (
		<>
			{responsiveContent.map(({ srcSet, media }, idx) => {
				return srcSet ? (
					<Fragment key={`${idx}_${srcSet}`}>
						{highPriority ? <PreloadImage href={defaultSrc} imageSrcSet={srcSet} media={media} /> : null}
						{includeSourceElement ? <source srcSet={srcSet} media={media} /> : null}
					</Fragment>
				) : null;
			})}
		</>
	);
}

export type IBackgroundImageAsset = JSX.IntrinsicElements['img'] & {
	/**
	 * Required field for the Desktop Image
	 *
	 * @type {IImage}
	 * @memberof IImageAsset
	 */
	desktopImage: IImage;
	/**
	 * Optional field for the Mobile Image
	 *
	 * @type {IImage}
	 * @memberof IImageAsset
	 */
	mobileImage?: IImage;
	/**
	 * Optional styles to apply to the image.
	 *
	 * @type {SxProps}
	 * @memberof IImageAsset
	 */
	imageSx?: SxProps<Theme>;
	/**
	 * Optional data-test-id attribute for testing purposes
	 *
	 * @type {string}
	 * @memberof IImageAsset
	 */
	dataTestId?: string;
};
function BackgroundImageAsset({
	desktopImage,
	mobileImage,
	imageSx,
	dataTestId,
	...rest
}: Readonly<IBackgroundImageAsset>) {
	const theme = useTheme();
	const images = [mobileImage, desktopImage].filter((v): v is IImage => !!(v?.image?.cdn_url || v?.image?.url));

	if (images.length === 0) {
		return null;
	}
	const styles = [imageSx, getBackgroundImageStylesResponsive(theme, images)].flatMap((v) => v);
	const defaultSrc = optimizeImageUrl(getImageUrl(desktopImage), undefined, BACKGROUND_IMAGE_QUALITY);
	return (
		<>
			<ResponsiveImageSource
				desktopImage={desktopImage}
				mobileImage={mobileImage}
				defaultSrc={defaultSrc}
				includeSourceElement={false}
			/>
			<ImagePriorityContext.Provider value={Infinity}>
				<ImageWrapper sx={styles} data-test-id={dataTestId} {...rest} />
			</ImagePriorityContext.Provider>
		</>
	);
}
