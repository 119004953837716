import { SxProps, Theme } from '@mui/material';

import { optimizeImageUrl } from '@/utils/images/imageOptimization';
import { BACKGROUND_IMAGE_QUALITY } from '@/constants';

import { resolveImagePosition } from '../FocalPointImage/FocalPointImage.helpers';
import { BrandFolderImage, FocalPointData } from '../FocalPointImage';

export interface IImage {
	image: BrandFolderImage;
	focalPoint: FocalPointData;
}

export const getImageFromArray = (images: IImage[], getAction: 'shift' | 'pop'): string | undefined => {
	const image = [...images][getAction]();
	if (!image) return '';
	return image.image.cdn_url ?? image.image.url ?? '';
};

export const getImagePosition = (images: IImage[], getAction: 'shift' | 'pop') => {
	const image = [...images][getAction]();
	return resolveImagePosition(image?.focalPoint, image?.image);
};

export const getAspectRatio = (images: IImage[], getAction: 'shift' | 'pop') => {
	const defaultRatio = '16 / 9';
	const image = [...images][getAction]();
	if (!image) return defaultRatio;
	return image.image.width && image.image.height ? `${image.image.width}/${image.image.height}` : defaultRatio;
};

export const getBackgroundImageStylesResponsive = (theme: Theme, image: IImage[]): SxProps<Theme> => ({
	display: 'block',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	height: '100%',
	minWidth: '100%',
	maxWidth: '100%',
	overflow: 'hidden',

	...(image.length > 1
		? {
				[theme.breakpoints.between('xs', 'sm')]: {
					backgroundImage: `url(${optimizeImageUrl(
						getImageFromArray(image, 'shift'),
						theme.breakpoints.values.sm,
						BACKGROUND_IMAGE_QUALITY
					)})`,
					backgroundPosition: getImagePosition(image, 'shift'),
					aspectRatio: getAspectRatio(image, 'shift'),
				},
				[theme.breakpoints.between('sm', 'md')]: {
					backgroundImage: `url(${optimizeImageUrl(
						getImageFromArray(image, 'shift'),
						undefined,
						BACKGROUND_IMAGE_QUALITY
					)})`,
					backgroundPosition: getImagePosition(image, 'shift'),
					aspectRatio: getAspectRatio(image, 'shift'),
				},
				[theme.breakpoints.up('md')]: {
					backgroundImage: `url(${optimizeImageUrl(
						getImageFromArray(image, 'pop'),
						undefined,
						BACKGROUND_IMAGE_QUALITY
					)})`,
					backgroundPosition: getImagePosition(image, 'pop'),
					aspectRatio: getAspectRatio(image, 'pop'),
				},
		  }
		: {
				backgroundImage: `url(${optimizeImageUrl(
					getImageFromArray(image, 'pop'),
					undefined,
					BACKGROUND_IMAGE_QUALITY
				)})`,
				backgroundPosition: getImagePosition(image, 'pop'),
				aspectRatio: getAspectRatio(image, 'pop'),
		  }),
});
